import React, { useEffect, useRef, useState } from "react"

// components
import FontSizeTextArea from "../../components/commons/FontSizeTextArea"
import CenterTextArea from "../../components/commons/CenterTextArea"
import PreviewCanvas from "../../components/commons/PreviewCanvas"
import CommonInner from "../../components/commons/CommonInner"
import CommonHead from "../../components/commons/CommonHead"
import BottomButtonContainer from "../../components/commons/BottomButtonContainer"

import * as styles from "./styles.module.scss"
import { navigate } from "gatsby-link"

const ERROR_TYPE = {
  length: "length",
  regexp: "regexp",
}

const Text = () => {
  const [imageData, setImageData] = useState(null)
  const [inputText, setInputText] = useState("")
  const [isLimitError, setLimitError] = useState(false)
  const [isTextError, setTextError] = useState(false)
  const [isSubmitEnabled, setIsSubmitEnabled] = useState(false)
  const [errorMessage, setErrorMessage] = useState(null)

  const textChange = text => {
    setInputText(text)

    const isEmpryNotError = text.length > 0
    const isLimitNotError = limitCheck(text)
    const isTextNotError = validationCheck(text)

    const isSubmit = isLimitNotError && isTextNotError && isEmpryNotError
    setIsSubmitEnabled(isSubmit)
  }

  const handleClick = () => {
    sessionStorage.setItem("text", inputText)
    navigate("/name")
  }

  const limitCheck = text => {
    const length = text.length
    const isError = length > 80
    setLimitError(isError)
    return !isError
  }

  const validationCheck = text => {
    const regex = /[^ぁ-んァ-ヶーa-zA-Z\u4E00-\u9FFF\u3005-\u3007\s]/
    const isError = regex.test(text)
    setTextError(isError)
    return !isError
  }

  useEffect(() => {
    let errors = []
    if (isLimitError) {
      errors.push("80字以内で入力してください")
    }
    if (isTextError) {
      errors.push(
        "ひらがな / カタカナ / アルファベット / 漢字のみで入力してください"
      )
    }
    if (errors.length === 0) {
      setErrorMessage(null)
    } else {
      setErrorMessage(errors.join("・"))
    }
  }, [isLimitError, isTextError])

  useEffect(() => {
    const image = sessionStorage.getItem("image")

    if (image) {
      setImageData(image)
    } else {
      navigate("/")
    }

    const savedText = sessionStorage.getItem("text")

    if (savedText) {
      setInputText(savedText)
      textChange(savedText)
    }
  }, [])

  return (
    <main className={styles.text}>
      <CommonInner>
        <CommonHead appendClass={styles.head}>今年やりたいことは？</CommonHead>
        <div className={styles.lead}>
          {errorMessage ? (
            <div className={styles.error}>{errorMessage}</div>
          ) : (
            <div className={styles.normal}>
              80字以内で入力してください・ひらがな / カタカナ / アルファベット /
              漢字のみで入力してください
            </div>
          )}
        </div>
      </CommonInner>
      <CenterTextArea
        placeholder="やりたいことを入力する"
        text={inputText}
        textChange={textChange}
      />
      <div className={styles.preview_container}>
        <PreviewCanvas imageData={imageData} />
      </div>
      <BottomButtonContainer
        clickNext={handleClick}
        disabled={!isSubmitEnabled}
        clickPrev={() => navigate(-1)}
        prevTitle="戻る"
      />
    </main>
  )
}

export default Text
