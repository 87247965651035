import React, { useRef, useEffect } from "react"

import * as styles from "./styles.module.scss"

const PreviewCanvas = ({ imageData }) => {
  const canvasRef = useRef(null)

  const setImage = (ref, imageData) => {
    if (ref && imageData) {
      const context = ref.current.getContext("2d")
      const image = new Image()
      image.src = imageData
      image.onload = () => {
        context.drawImage(image, 0, 0)
      }
    }
  }

  useEffect(() => {
    setImage(canvasRef, imageData)
  }, [canvasRef, imageData])

  return (
    <div className={styles.preview_canvas}>
      <canvas
        className={styles.canvas}
        width="300"
        height="325"
        ref={canvasRef}
      />
    </div>
  )
}

export default PreviewCanvas
